import styled from "styled-components";
import myImage from '../../assets/images/image4.jpg';

// instead of doing styled.img use styled.div because bg related property will help as we will split images
export const Image = styled.div.attrs(({$movedX, $movedY}) => ({
    style: {
        transform: `translate(${$movedX}px, ${$movedY}px)`,
    },
}))`
  background-image: url(${myImage});
  background-position: ${({$initialX, $initialY}) => (`-${$initialX * 100}px -${$initialY * 100}px`)};

  background-size: 400px 600px;
  height: 100px;
  width: 100px;
  position: relative;
  //z-index: 2;
`


