import { Footer, H2, H2Middle, LinkStyle } from "./FooterStyles";

const FooterComponent = () => {
  return (
    <Footer>
        <LinkStyle target = "_blank" href="https://www.behance.net/niveshbirangal">Behance</LinkStyle>
      <H2Middle> </H2Middle>
        <LinkStyle target = "_blank" href="https://dribbble.com/niveshbirangal">Dribbble</LinkStyle>
    </Footer>
  );
};

export default FooterComponent;
