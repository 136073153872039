import styled, {css} from 'styled-components'
import {Marginals} from "../../styles/styles";

// import {ReactComponent as LogoSVG} from './logo.svg' // fuck this shit why it does not work with webpack?

// const LogoSVG = () =>{
//
//     return (
//         <svg width="767" height="118" viewBox="0 0 767 118" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M110.572 11.8604V113.5H91.476V60.5243L92.4 30.8024H92.092L63.91 113.5H46.508L18.48 30.8024H18.172L19.25 60.5243V113.5H0V11.8604H30.8L49.126 67.3004L55.594 90.2464H55.902L62.524 67.4543L80.696 11.8604H110.572Z" fill="#FBFBFB"/>
//             <path d="M143.628 11.8604V113.5H122.376V11.8604H143.628Z" fill="#FBFBFB"/>
//             <path d="M176.672 96.4064H230.264V113.5H155.42V11.8604H176.672V96.4064Z" fill="#FBFBFB"/>
//             <path d="M323.986 11.8604L277.478 64.6824L275.784 62.9884L250.374 91.7864L247.14 72.0744L299.346 11.8604H323.986ZM257.612 11.8604V113.5H236.36V11.8604H257.612ZM283.33 46.9724L325.218 113.5H300.886L267.006 58.8303L283.33 46.9724Z" fill="#FBFBFB"/>
//             <path d="M410.951 11.8604L374.915 75.4624V113.5H353.663V75.4624L317.627 11.8604H340.265L356.589 42.1984L364.289 58.2144L372.143 42.1984L388.467 11.8604H410.951Z" fill="#FBFBFB"/>
//             <path d="M419.728 115.5V112.42L431.894 107.8L435.128 21.868L421.884 16.94V13.86H448.526L484.716 98.252L521.368 13.86H546.932V16.94L533.996 21.714L536.614 107.8L547.394 112.42V115.5H513.514V112.42L524.91 107.954L523.216 28.182L485.024 116.27H479.634L442.212 28.49L440.364 107.954L452.838 112.42V115.5H419.728Z" fill="#FBFBFB"/>
//             <path d="M585.773 117.04C579.203 117.04 573.453 115.449 568.525 112.266C563.597 109.083 559.747 104.771 556.975 99.33C554.203 93.8887 552.817 87.7287 552.817 80.85C552.817 73.9713 554.203 67.8113 556.975 62.37C559.747 56.9287 563.597 52.6167 568.525 49.434C573.453 46.2513 579.203 44.66 585.773 44.66C592.344 44.66 598.093 46.2513 603.021 49.434C607.95 52.6167 611.8 56.9287 614.572 62.37C617.344 67.8113 618.729 73.9713 618.729 80.85C618.729 87.7287 617.344 93.8887 614.572 99.33C611.8 104.771 607.95 109.083 603.021 112.266C598.093 115.449 592.344 117.04 585.773 117.04ZM587.93 111.65C592.242 111.65 595.835 110.315 598.71 107.646C601.584 104.977 603.74 101.537 605.177 97.328C606.615 93.016 607.333 88.55 607.333 83.93C607.333 78.078 606.461 72.5853 604.715 67.452C603.073 62.216 600.506 58.0067 597.015 54.824C593.627 51.5387 589.264 49.896 583.925 49.896C579.613 49.896 575.969 51.1793 572.992 53.746C570.117 56.3127 567.909 59.7007 566.369 63.91C564.932 68.1193 564.214 72.688 564.214 77.616C564.214 83.5707 565.086 89.166 566.831 94.402C568.577 99.5353 571.195 103.693 574.685 106.876C578.176 110.059 582.591 111.65 587.93 111.65Z" fill="#FBFBFB"/>
//             <path d="M657.239 117.04C650.668 117.04 644.919 115.449 639.991 112.266C635.063 109.083 631.213 104.771 628.441 99.33C625.669 93.8887 624.283 87.7287 624.283 80.85C624.283 73.9713 625.669 67.8113 628.441 62.37C631.213 56.9287 635.063 52.6167 639.991 49.434C644.919 46.2513 650.668 44.66 657.239 44.66C663.81 44.66 669.559 46.2513 674.487 49.434C679.415 52.6167 683.265 56.9287 686.037 62.37C688.809 67.8113 690.195 73.9713 690.195 80.85C690.195 87.7287 688.809 93.8887 686.037 99.33C683.265 104.771 679.415 109.083 674.487 112.266C669.559 115.449 663.81 117.04 657.239 117.04ZM659.395 111.65C663.707 111.65 667.3 110.315 670.175 107.646C673.05 104.977 675.206 101.537 676.643 97.328C678.08 93.016 678.799 88.55 678.799 83.93C678.799 78.078 677.926 72.5853 676.181 67.452C674.538 62.216 671.972 58.0067 668.481 54.824C665.093 51.5387 660.73 49.896 655.391 49.896C651.079 49.896 647.434 51.1793 644.457 53.746C641.582 56.3127 639.375 59.7007 637.835 63.91C636.398 68.1193 635.679 72.688 635.679 77.616C635.679 83.5707 636.552 89.166 638.297 94.402C640.042 99.5353 642.66 103.693 646.151 106.876C649.642 110.059 654.056 111.65 659.395 111.65Z" fill="#FBFBFB"/>
//             <path d="M721.775 117.04C716.641 117.04 712.073 115.757 708.069 113.19C704.065 110.623 700.882 106.927 698.521 102.102C696.262 97.2767 695.133 91.5787 695.133 85.008C695.133 76.384 696.775 69.0947 700.061 63.14C703.449 57.0827 707.915 52.514 713.459 49.434C719.105 46.2513 725.214 44.66 731.785 44.66C734.454 44.66 736.918 44.8653 739.177 45.276C741.435 45.6867 743.54 46.2513 745.491 46.97V13.398L735.019 7.39201V5.85201L754.731 0H755.963V109.34L766.897 113.036V115.5H747.031L745.645 107.646H744.875C741.076 110.829 737.329 113.19 733.633 114.73C729.937 116.27 725.984 117.04 721.775 117.04ZM705.297 81.928C705.297 88.088 706.375 93.1187 708.531 97.02C710.687 100.921 713.51 103.847 717.001 105.798C720.594 107.646 724.444 108.57 728.551 108.57C731.425 108.57 734.3 108.108 737.175 107.184C740.152 106.157 742.924 104.669 745.491 102.718V56.826C742.719 54.8753 739.998 53.3867 737.329 52.36C734.659 51.3333 731.682 50.82 728.397 50.82C724.29 50.82 720.44 51.8467 716.847 53.9C713.356 55.9533 710.533 59.29 708.377 63.91C706.323 68.4273 705.297 74.4333 705.297 81.928Z" fill="#FBFBFB"/>
//         </svg>
//     )
//
// }
const LogoSVG = () => {
    return (<svg width="303" height="116" viewBox="0 0 303 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4 1.99999L72.32 84.88V1.99999H87.84V114H74.08L16 31.28V114H0.48V1.99999H14.4ZM177.304 1.99999V16.72H128.344V47.44H172.024V62.16H128.344V99.28H177.304V114H112.824V1.99999H177.304ZM245.575 0.399994C256.028 0.399994 265.575 2.95999 274.215 8.07999C282.962 13.0933 289.788 19.9733 294.695 28.72C299.708 37.36 302.215 47.0133 302.215 57.68C302.215 68.4533 299.708 78.2667 294.695 87.12C289.788 95.9733 283.015 102.96 274.375 108.08C265.735 113.093 256.135 115.6 245.575 115.6C235.015 115.6 225.415 113.093 216.775 108.08C208.135 102.96 201.308 95.9733 196.295 87.12C191.388 78.2667 188.935 68.4533 188.935 57.68C188.935 47.0133 191.388 37.36 196.295 28.72C201.308 19.9733 208.135 13.0933 216.775 8.07999C225.415 2.95999 235.015 0.399994 245.575 0.399994ZM245.575 15.28C238.002 15.28 231.068 17.1467 224.775 20.88C218.588 24.6133 213.682 29.7333 210.055 36.24C206.535 42.7467 204.775 49.8933 204.775 57.68C204.775 65.68 206.535 72.9867 210.055 79.6C213.682 86.1067 218.588 91.28 224.775 95.12C231.068 98.8533 238.002 100.72 245.575 100.72C253.148 100.72 260.028 98.8533 266.215 95.12C272.508 91.28 277.415 86.1067 280.935 79.6C284.562 72.9867 286.375 65.68 286.375 57.68C286.375 49.8933 284.562 42.7467 280.935 36.24C277.415 29.7333 272.508 24.6133 266.215 20.88C260.028 17.1467 253.148 15.28 245.575 15.28Z" fill="white"/>
        </svg>
    )

}




export const Logo = styled(LogoSVG)`
  height: auto;
  max-width: 760px;
  width: 100%;
`

 export const Header = styled.header`
   ${Marginals};
   padding: 30px;
   justify-content: center;
   
@media (min-width: 700px) {
  padding: 15px;
  
}
   
 `


