import { createRoot } from "react-dom/client";
import { StrictMode } from "react";

// Reset CSS
import "../../../extra/reset.css";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
